import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forkJoin, map } from 'rxjs';
import { ApprovalsService } from '@services';
import { ExpensesPdfDetails, ExpensImageFile } from '@interfaces';
@Injectable({
  providedIn: 'root'
})
export class ExpensesApiService {
  
  constructor(private readonly http: HttpClient,private readonly approvalServices:ApprovalsService) { }

    fetchExpenseCategory(): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/expenses/category`);
    }
    
    fetchExpenseCategoryType(endpoint:string): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/expenses/type/${endpoint}`);
    }

    fetchExpenseCurrencyList(): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/expenses/currency-list`);
    }
    
    fetchExpenseCurrencyExchange(query_string:string): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/expenses/currency/exchange?${query_string}`);
    }
    fetchVesselList(): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/vessel-list`);
    }
  
    fetchTrainingList(): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/training-type`);
    }

    fetchExpenseDetail(id:string): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/expenses/${id}`);
    }

    approveExpenseDetail(id: any,formData:any): Observable<any> {
      return this.http.put(`${environment.BACKEND_API_BASE_URL}/document-approval/${id}`, formData );
    }

    downloadZip(attachmentfiles:ExpensImageFile[]) {
      const zip = new JSZip();
      const getFileContent = (file: { fileName: string, name: string }) =>
        this.approvalServices.getPDFContent({ fileName: file.fileName }).pipe(
          map((response: ExpensesPdfDetails) => ({
            name: file.name,
            content: response.data.data
          }))
        );
      const fileObservables = attachmentfiles?.map(getFileContent);
      forkJoin(fileObservables).subscribe({
        next: (results) => {
          this.addFilesToZip(results, zip);
          this.generateZipFile(zip);
        },
        error: (err) => {
          this.handleError(err);
        }
      });
    }

    private addFilesToZip(files: { name: string, content: ArrayBuffer | Blob }[], zip: JSZip) {
      files.forEach(({ name, content }) => {
        zip.file(name, content);
      });
    }
  
    private generateZipFile(zip: JSZip) {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, 'Download-attachements.zip');
      }).catch(err => {
        console.error('Error generating ZIP file:', err);
      });
    }
    private handleError(error: any) {
      console.error('Error while fetching or processing files for download all attachements:', error);
    }
  
  
}
