import { Injectable, signal } from '@angular/core';
import { SnlDropdowns } from '@interfaces';
import { BehaviorSubject } from 'rxjs';
import { INPUT_ERROR_MESSAGE } from '../../constants/stolt-constant';


@Injectable({
  providedIn: 'root'
})
export class CrewSelfService {
   snlDropdownData = signal<SnlDropdowns>({
    countriesData:[],
    relationshipsData: [],
    maritalStatusData:[],
    nationalityData: [],
   })
  private readonly role$: BehaviorSubject<any> = new BehaviorSubject(0);
  
  constructor() { 
    // This is intentional
  }

  setUserRole(role: any) {
    this.role$.next(role);
  }

  getUserRole() {
    return this.role$.asObservable();
  }

  dropdownOptions(data: any, id: string, name: string, toUpperCase = false) {
    let options: { id: any; name: any; }[] = [];
    data.forEach((element: any) => {
      if (element[id] && element[name]) {
        options.push({ id: element[id], name: (toUpperCase) ? element[name].toUpperCase() : element[name] });
      }

    });
    return options;
  }

  public showInputError(field: string, type: string, data: any) {
    if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('pattern')) {
      return this.errorMsg(field, type);
    }
    else if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('maxlength')) {
      return this.errorMsg(field, 'maxlength');
    }
    else if (data.get(field)!.invalid && data.get(field)!.touched && data.get(field)!.hasError('max')) {
      return this.errorMsg(field, 'max');
    }
    return '';
  }

  errorMsg(field: string, type: string) {
    if (type == 'string') {
      return INPUT_ERROR_MESSAGE.CHARACTOR_ONLY;
    }
    else if (type == 'number') {
      return INPUT_ERROR_MESSAGE.NUMBER_ONLY;
    }
    else if (type == 'special') {
      return INPUT_ERROR_MESSAGE.SPECIAL;
    }
    else if (type == 'maxlength') {
      return INPUT_ERROR_MESSAGE.MAXLENGTH;
    }
    else if (type == 'max') {
      return INPUT_ERROR_MESSAGE.PERCENTAGE_MAX;
    }
    else if (type == 'percentage') {
      return INPUT_ERROR_MESSAGE.PERCENTAGE_DATA_FORMET;
    }
    else {
      return '';
    }

  }

}