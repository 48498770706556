import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CrewSelfService {
  
  private readonly role$: BehaviorSubject<any> = new BehaviorSubject(0);
  
  constructor() { 
    // This is intentional
  }

  setUserRole(role: any) {
    this.role$.next(role);
  }

  getUserRole() {
    return this.role$.asObservable();
  }

}