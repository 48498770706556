import { ApprovalsPath, ModuleType } from "@enums";

export const ROUTING = {
    HOME: `/`,
    
    APPROVALS:`/${ModuleType.ManageApprovals}`,
    HISTORY:`/${ModuleType.ManageHistory}`,
    HISTORY_PROFILE_DETAILS:`/${ModuleType.ManageHistory}/${ApprovalsPath.PROFILE_DETAILS}`,
    APPROVALS_DETAILS:`/${ModuleType.ManageApprovals}/${ApprovalsPath.PROFILE_DETAILS}`,
    APPROVALS_DETAILS_DOCUMENTS:`/${ModuleType.ManageApprovals}/${ApprovalsPath.DOCUMENT_DETAILS}`,
    APPROVALS_DETAILS_BENEFICIARIES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.BENEFICIARIES_DETAILS}`,
    CASH_ACCOUNT_MANAGEMENT:`/${ModuleType.ManageCashAccountManagement}`,
    NEWSANDANNOUNCEMENTS:`/${ModuleType.ManageNewsAndAnnouncements}`,
    CREATE_NEWS:`/${ModuleType.ManageNewsAndAnnouncements}/${ApprovalsPath.CREATE_NEWS}`,

    APPROVALS_DETAILS_EXPENSES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.EXPENSES_DETAILS}`,
    APPROVALS_DETAILS_CASH_ADVANCES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.CASH_ADVANCES_DETAILS}`,
  };

  

  export const BACKEND_ENDPOINTS = {
    APPROVALS:{
      PROFILE_LISTING : () => `profile-approval`,
      PROFILE_DETAILS : (id:string) => `profile-approval/${id}`,
      APPROVALS_COUNT : () => `approvals-count`,
      PROFILE_APPROVAL : (id:string) => `profile-approval/${id}`,
      BENEFICIARIES_DETAILS : (id:string) => `beneficiaries-approval/${id}`,
      BENEFICIARIES_APPROVAL : (id:string) => `beneficiaries-approval/${id}`,
      DOCUMENT_LISTING : () => `document-approval`,
      DOCUMENT_DETAILS : (id:string) => `document-approval/${id}`,
      DOCUMENT_PDF_CONTENT : () => `document-approval/load-attachment`,
      BENEFICIARIES_LISTING : () => `beneficiary-approvals`,
      CASH_ADVANCES_LISTING : () => `cash-advances`,
      CASH_ADVANCES_FILTERS : () => `cash-advances/filters`,
      //BENEFICIARIES_DETAILS : (id:string) => `beneficiary-approvals/${id}`,
      EXPENSES_LISTING : () => `expenses-approvals`,
      EXPENSES_FILTER_LISTING : () => `expenses-approvals/filters`,
      EXPENSES_DETAILS : (id:string) => `expenses-approvals/${id}`,
      EXPENSES_APPROVAL : (id:string) => `expenses-approvals/${id}`,
      BENEFECIARIES_LISTING : () => `beneficiaries-approval`,
    },
    HISTORY:{
      PROFILE_HISTORY : () => `history/profile`,
      PROFILE_LISTING : () => `history/profile`,
      PROFILE_DETAILS : (id:string) => `history/profile/${id}`,
	    DOCUMENT_HISTORY : () => `history/document`,
      CASH_ADVANCES_HISTORY : () => `history/cash-advances`,
      CASH_ADVANCES_FILTERS : () => `history/cash-advances/filters`,
      CASH_ADVANCES_HISTORY_DETAILS : (id:string) => `history/cash-advances/${id}`,
      DOCUMENT_LISTING : () => `history/document`,
      //DOCUMENT_DETAILS : (id:string) => `document-history/${id}`,
	    BENEFECIARIES_HISTORY : () => `history/beneficiary `,
      DOCUMENT_HISTORY_DETAILS : (id:string) => `history/document/${id}`,
      BENEFICIARIES_HISTORY_DETAILS : (id:string) => `history/beneficiary/${id}`,
      EXPENSES_HISTORY : () => `history/expenses`,
      EXPENSES_FILTER_LISTING : () => `history/expenses/filters`,
      EXPENSES_HISTORY_DETAILS : (id:string) => `history/expenses/${id}`,
      HISTORY_COUNT : () => `history/count`,
    },
    SETTINGS:{
      ROUTE_SETTINGS : () => `manager-settings`,
      ROUTE_CASH_ADVANCE_SETTINGS : () => `admin-settings/cash_advances`,
      ROUTE_WELFARE_SETTINGS : () => `admin-settings/welfares`,
      ROUTE_EXPENSE_SETTINGS : () => `admin-settings/expenses`,
      ROUTE_UPDATE_EXPENSE_SETTINGS : () => `admin-settings/expense`,
    },
    NEWSANDANNOUNCEMENTS:{
      CREATE_NEWS : () => `announcements`,
      GET_IMAGE_SIZE : () => `announcements/imageSize`,
      ADD_CREW_MEMBERS : () => `announcements/add-crew-member`,
      ADD_SHIPS : () => `announcements/add-ship`,
      REMOVE_ALL_SHIPS : () => `announcements/remove-ship`,
      REMOVE_SELECTED_CREW_MEMBERS : () => `announcements/remove-crew-member`,
      REMOVE_SELECTED_SHIPS : () => `announcements/remove-ship`,
      REMOVE_ALL_CREW_MEMBERS : () => `announcements/remove-crew-member`,
      NEWS_DETAILS : (id:string) => `announcements/${id}`,
      PUBLISH_NEWS_DETAILS : (id:string) => `announcements/publish/${id}`,
      PUBLISH_NEWS_CREW_LIST : (id:string) => `announcements/publish/crew/${id}`,
      PUBLISH_NEWS_FILTER : (id:string) => `announcements/publish/filter/${id}`,
      GET_CREW_MEMBERS : (query:string) => `get-crew-members?${query}`,
      SELECTED_SHIPS : (id:string) => `announcements/${id}`,
      SHIP_LIST : () => `master-data/vessels`,
      CREW_LIST : () => `crews/active-members`,
      ANNOUNCEMENT_LIST : () => `announcements/lists`,
      CREW_LIST_FILTERS : () => `crews/active-members/filters`,
      ANNOUNCEMENT_LIST_FILTERS : () => `announcements/lists/filters`,
      DELETE_NEWS : (id:string) => `announcements/${id}`,
    }
  };
  