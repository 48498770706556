import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments';
import { Observable } from 'rxjs';
import { BACKEND_ENDPOINTS } from '@routes';
import { ApprovedRequestPayload, ProfileApprovalLoader, ProfileApprovals } from '@interfaces';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApprovalsService {
  profileApprovalLoader = signal<ProfileApprovalLoader>({
    isSkelton: true,
    isLoading: true,
  })
  profile = signal<ProfileApprovals>({
    personalData: [],
    familyMembers: [],
  });


  constructor(private readonly http: HttpClient) { }


  fetchApprovelsProfileList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.PROFILE_LISTING()}`);
  }

  fetchApprovelsProfileDetails(id:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.PROFILE_DETAILS(id)}`);
  }

  approvedProfileData(id:string,data:ApprovedRequestPayload): Observable<any>{
    return this.http.put(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.PROFILE_APPROVAL(id)}`,data);
  }
 
  fetchApprovelsDocumentList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.DOCUMENT_LISTING()}`);
  }
  fetchApprovelsBeneficiariesList(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.BENEFICIARIES_LISTING()}`);
  }

  fetchApprovelsCashAdvancesList(data: any): Observable<any> {
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.CASH_ADVANCES_LISTING()}`,data)
  }

  fetchApprovelsCashAdvancesFilters(data: any): Observable<any> {
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.CASH_ADVANCES_FILTERS()}`,data)
}

  fetchApprovelsCount(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.APPROVALS_COUNT()}`);
  }
  fetchApprovelsDocumentDetails(id:string): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.DOCUMENT_DETAILS(id)}`);
  }
  getPDFContent(filData:{fileName:string}): Observable<any>{
    return this.http.post(`${environment.BACKEND_API_BASE_URL}/${BACKEND_ENDPOINTS.APPROVALS.DOCUMENT_PDF_CONTENT()}`,filData);
  }
 
}
