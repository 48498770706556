import { ApprovalsPath, ModuleType } from "@enums";

export const ROUTING = {
    HOME: `/`,
    
    APPROVALS:`/${ModuleType.ManageApprovals}`,
    HISTORY:`/${ModuleType.ManageHistory}`,
    HISTORY_PROFILE_DETAILS:`/${ModuleType.ManageHistory}/${ApprovalsPath.PROFILE_DETAILS}`,
    APPROVALS_DETAILS:`/${ModuleType.ManageApprovals}/${ApprovalsPath.PROFILE_DETAILS}`,
    APPROVALS_DETAILS_DOCUMENTS:`/${ModuleType.ManageApprovals}/${ApprovalsPath.DOCUMENT_DETAILS}`,
    APPROVALS_DETAILS_BENEFICIARIES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.BENEFICIARIES_DETAILS}`,
    APPROVALS_DETAILS_EXPENSES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.EXPENSES_DETAILS}`,
    APPROVALS_DETAILS_CASH_ADVANCES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.CASH_ADVANCES_DETAILS}`,
    CASH_ACCOUNT_MANAGEMENT:`/${ModuleType.ManageCashAccountManagement}`
  };

  

  export const BACKEND_ENDPOINTS = {
    APPROVALS:{
      PROFILE_LISTING : () => `profile-approval`,
      PROFILE_DETAILS : (id:string) => `profile-approval/${id}`,
      APPROVALS_COUNT : () => `approvals-count`,
      PROFILE_APPROVAL : (id:string) => `profile-approval/${id}`,
      BENEFICIARIES_DETAILS : (id:string) => `beneficiaries-approval/${id}`,
      BENEFICIARIES_APPROVAL : (id:string) => `beneficiaries-approval/${id}`,
      DOCUMENT_LISTING : () => `document-approval`,
      DOCUMENT_DETAILS : (id:string) => `document-approval/${id}`,
      DOCUMENT_PDF_CONTENT : () => `document-approval/load-attachment`,
      BENEFICIARIES_LISTING : () => `beneficiary-approvals`,
      CASH_ADVANCES_LISTING : () => `cash-advances`,
      CASH_ADVANCES_FILTERS : () => `cash-advances/filters`,
      //BENEFICIARIES_DETAILS : (id:string) => `beneficiary-approvals/${id}`,
      EXPENSES_LISTING : () => `expenses-approvals`,
      EXPENSES_DETAILS : (id:string) => `expenses-approvals/${id}`,
      EXPENSES_APPROVAL : (id:string) => `expenses-approvals/${id}`,
      BENEFECIARIES_LISTING : () => `beneficiaries-approval`,
    },
    HISTORY:{
      PROFILE_HISTORY : () => `history/profile`,
      PROFILE_LISTING : () => `history/profile`,
      PROFILE_DETAILS : (id:string) => `history/profile/${id}`,
	    DOCUMENT_HISTORY : () => `history/document`,
      DOCUMENT_LISTING : () => `history/document`,
      //DOCUMENT_DETAILS : (id:string) => `document-history/${id}`,
	    BENEFECIARIES_HISTORY : () => `history/beneficiary `,
      DOCUMENT_HISTORY_DETAILS : (id:string) => `history/document/${id}`,
      BENEFICIARIES_HISTORY_DETAILS : (id:string) => `history/beneficiary/${id}`,
      HISTORY_COUNT : () => `history/count`,
    },
    SETTINGS:{
      ROUTE_SETTINGS : () => `manager-settings`,
      ROUTE_CASH_ADVANCE_SETTINGS : () => `admin-settings/cash_advances`,
      ROUTE_WELFARE_SETTINGS : () => `admin-settings/welfares`,
      ROUTE_EXPENSE_SETTINGS : () => `admin-settings/expenses`,
      ROUTE_UPDATE_EXPENSE_SETTINGS : () => `admin-settings/expense`,
    }
  };
  