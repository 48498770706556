import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
 
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  checkPermission(arrModules: string[]): boolean {
    if (this.authService.UserObj().modules) {
      if (arrModules.length === 0) {
        return false;
      }
      const approvalModule = new Set(this.authService.UserObj().modules);

      return arrModules.some(item => approvalModule.has(item));
    }
    return false;
  }
  
}
