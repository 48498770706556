export enum ModuleType {
    ManageApprovals = 'approvals',
    ManageCashAccountManagement = 'cash-account-management',
    ManageSettings = 'settings',
    ManageHistory = 'history',
}

export enum ApprovalsPath {
  PROFILE_DETAILS = 'details/profile',
  DOCUMENT_DETAILS = 'details/documents',
  BENEFICIARIES_DETAILS = 'details/beneficiaries',
  EXPENSES_DETAILS = 'details/expenses',
  CASH_ADVANCES_DETAILS = 'details/cashadvances',
}