import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forkJoin, map } from 'rxjs';
import { ApprovalsService } from '@services';
import { ExpensesPdfDetails, ExpensImageFile } from '@interfaces';
@Injectable({
  providedIn: 'root'
})
export class FormApiService {
  
  constructor(private readonly http: HttpClient,private readonly approvalServices:ApprovalsService) { }

    fetchFormList(formData:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/lists/`, formData);
    }

    getFormPreviewData(id:string): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/forms/${id}`);
    }

    submitFormDetail(formData:any): Observable<any> {
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms`, formData );
    }

    fetchFormFilterList(filterdata:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/lists/filters`,filterdata);
    }

    fetchAddCrewList(formData:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/crews/active-members/`, formData);
    }

    fetchAddCrewFilterList(filterdata:any): Observable<any>{ 
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/crews/active-members/filters`,filterdata);
    }
    
    addCrewMember(formData:any): Observable<any> {
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/add-crew-member`, formData );
    }

    removeSelectedCrewMembers(formData:any): Observable<any> {
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/remove-crew-member`, formData );
    }  

    getSelectedCrewList(id:any,search:any='',page:any=1,limit:any=10): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/forms/${id}?search=${search}&page=${page}&limit=${limit}`);
    }

    removeAllCrewMembers(postData:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/remove-all-crew-members`, postData);
    }

    fetchRankList(): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/master-data/ranks`);
    }

    deleteForm(id:string): Observable<any> {
      return this.http.delete(`${environment.BACKEND_API_BASE_URL}/forms/${id}`);
    }

    fetchFormCount(): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/forms/form-count`);
    }

    getCrewMembers(postData:any,query_string:string): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/get-crew-members?${query_string}`,postData);
    }

    getSelectedCrewPublishList(id:any,filterData:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/publish/crew/${id}`,filterData);
    }

    getSelectedCrewPublishListFilter(id:any,filterData:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/publish/filter/${id}`,filterData);
    }

    getPublishCrewList(id:any): Observable<any>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/forms/publish/${id}`);
    }
    
    getResponseFormData(id:any,crewId:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/publish/form/${id}/response`,crewId);
    }

    sendReminder(postData:any): Observable<any>{
      return this.http.post(`${environment.BACKEND_API_BASE_URL}/forms/send-reminder`,postData);
    }
    
    downloadExcel(id:any): Observable<Blob>{
      return this.http.get(`${environment.BACKEND_API_BASE_URL}/forms/download-excel/${id}`, { responseType: 'blob' });
    }

}
