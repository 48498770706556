import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoltApiService {
  
  constructor(
    private readonly http: HttpClient) {}

  fetchRanks(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_URL}master-data/ranks`);
  }

  getUserDetail(): Observable<any>{
    return this.http.get(`${environment.BACKEND_API_URL}user-roles`);
  }
}
