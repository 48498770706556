import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthUser } from '../../interfaces/manager.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
 
  public UserObj = signal<AuthUser>({
    user:{},
    modules: []
  });
 
  constructor(
    private _http: HttpClient
  ) {
     
  }
  
  setUserAuthData(objData: Partial<AuthUser>) {
    const currentData = this.UserObj();
    const updatedUserAuthData: AuthUser = {
      ...currentData,
      ...objData,  
    };
    this.UserObj.set(updatedUserAuthData);
  }

}
