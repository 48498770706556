import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '@environments';
 
import { filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from '@services';

type ProfileType = {
	givenName?: string,
	surname?: string,
	displayName?: string,
	userPrincipalName?: string,
	id?: string
  };

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	title = 'crew-manager-app';
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();
	constructor(
		private readonly broadcastService: MsalBroadcastService,
		private readonly msalService: MsalService,
		private readonly http: HttpClient,
		private readonly authService:AuthService
	){}
  
	ngOnInit(){
		this.broadcastService.inProgress$
		.pipe(
		  filter((status: InteractionStatus) => status === InteractionStatus.None),
		  takeUntil(this._destroying$)
		)
		.subscribe(() => {
		  this.setLoginDisplay();
		  this.getProfile();
		})
	}
  
	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
	}
	getProfile() {
		this.http.get(environment.AAD_GRAPH_ENDPOINT)
		  .subscribe((profile:ProfileType) => {
			this.authService.setUserAuthData({user:profile});
		});
	}
}
